var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mx-auto",style:({ 'margin-top':'80px' }),attrs:{"max-width":"400","elevation":"6"}},[_c('v-toolbar',{attrs:{"dark":"","color":"#0d6efd","flat":""}},[_c('v-toolbar-title',[_vm._v("Provincial Government of Cagayan Portal")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-img',{style:({ margin: '0 auto', width: '40%', height: 'auto' }),attrs:{"lazy-src":require("../assets/hdlogo.png"),"src":require("../assets/hdlogo.png")}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Username","placeholder":"Username","outlined":"","rules":[
                _vm.vv.init(_vm.payload.username, 'Username'),
                _vm.vv.required(),
              ]},model:{value:(_vm.payload.username),callback:function ($$v) {_vm.$set(_vm.payload, "username", $$v)},expression:"payload.username"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"input-group--focused",attrs:{"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show2 ? 'text' : 'password',"name":"input-10-2","label":"Password","outlined":"","rules":[
                  _vm.vv.init(_vm.payload.password, 'Password'),
                  _vm.vv.required(),
                ]},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.payload.password),callback:function ($$v) {_vm.$set(_vm.payload, "password", $$v)},expression:"payload.password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{style:({ 'width': '100%' }),attrs:{"color":"#0d6efd","dark":"","rounded":"","x-large":""},on:{"click":function($event){return _vm.signin()}}},[_vm._v(" Sign-in ")])],1),(_vm.invalidCrid)?_c('v-col',{attrs:{"cols":"12 mt-5"}},[_c('v-alert',{attrs:{"border":"left","colored-border":"","type":"error","elevation":"2","color":"red accent-4"}},[_c('span',{style:({ 'color': 'red' })},[_vm._v("Invalid Credentials")])])],1):_vm._e(),(_vm.notPermitted)?_c('v-col',{attrs:{"cols":"12 mt-5"}},[_c('v-alert',{attrs:{"border":"left","colored-border":"","type":"error","elevation":"2","color":"red accent-4"}},[_c('span',{style:({ 'color': 'red' })},[_vm._v("It is not authorized for your account to use this application.")])])],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }