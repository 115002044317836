import axios from 'axios';
export default {

    state: {
        mime: [],
        share_user: [],
        share_department: [],
        share_division: []
    },
    getters: {
        getMime(state) {
            return state.mime;
        },
        getShareUser(state) {
            return state.share_user;
        },
        getShareDepartment(state) {
            return state.share_department;
        },
        getShareDivision(state) {
            return state.share_division;
        }
    },
    actions: {
        fetchMimeTypes({
            commit
        }) {
            axios({
                    method: "get",
                    url: process.env.VUE_APP_API + "arch/fecthMimeTypes",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("ua")
                    }
                })
                .then((resp) => {
                    commit('SET_MIME', resp.data);
                })
                .catch((err) => {

                })
        },
        fetchShares({
            commit
        }, payload) {
            return new Promise((resolve, reject) => {
                axios({
                        method: "get",
                        url: process.env.VUE_APP_API + "arch/fetchShareUsers",
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("ua")
                        },
                        params: payload
                    })
                    .then((resp) => {
                       
                        switch(payload.type) {
                            case 0:
                                commit('SET_SHARE_USER', resp.data);
                                break;
                            case 1:
                                commit('SET_SHARE_DEPARAMENT', resp.data);
                                break;
                            case 2:
                                commit('SET_SHARE_DIVISION', resp.data);
                                break;
                          }
                          resolve(resp);
                        
                    })
                    .catch((err) => {
                        reject(err);
                    })
            });

        }

    },
    mutations: {
        SET_MIME(state, data) {
            state.mime = data;
        },
        SET_SHARE_USER(state, data) {

            for (let i = 0; i < data.length; i++) {

                data[i].color = "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase();
            }
            state.share_user = data;
        },
        SET_SHARE_DEPARAMENT(state, data) {
            
            for (let i = 0; i < data.length; i++) {

                data[i].color = "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase();
            }
            
            state.share_department = data;
        },
        SET_SHARE_DIVISION(state, data) {
            
            for (let i = 0; i < data.length; i++) {

                data[i].color = "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase();
            }
            
            state.share_division = data;
        },

    }
}