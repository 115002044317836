import axios from 'axios';
export default {

    state: {
        isAuth: false,
        user: [],
        roles: []
    },
    getters: {
        getAuthStatus(state) {
            return state.isAuth;
        },
        getCurrentUser(state) {
            return state.user;
        },
        getRoles(state) {
            return state.roles;
        }

    },
    actions: {

        signOut({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: "post",
                    url: process.env.VUE_APP_API + "auth/logout",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("ua")
                    }
                })
                    .then((resp) => {
                        commit('REMOVE_USER', resp.data);
                        localStorage.removeItem("ua");
                        resolve("1");
                    })
                    .catch((err) => {
                        reject("0");
                    })
            })
        },
        checkAuth({ commit }) {
            if (localStorage.getItem("ua") != null) {
                axios({
                    method: "post",
                    url: process.env.VUE_APP_API + "auth/me",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("ua")
                    },
                    
                })
                    .then((resp) => {
                        commit('SET_USER', resp.data);
                       
                    })
                    .catch((err) => {

                    })
            }

        },
       
        hasAuthorization(){
            return new Promise((resolve, reject) =>{
                if (localStorage.getItem("ua") != null) {
                    axios({
                        method: "post",
                        url: process.env.VUE_APP_API + "auth/me",
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("ua")
                        }
                    })
                        .then((resp) => {
                            resolve(resp)
                        })
                        .catch((err) => {
                            reject(err)
                        })
                }else{
                    reject(false)
                }
            });
        }


    },
    mutations: {
        SET_USER(state, data) {
            state.isAuth = true;
            state.user = data;
            state.roles = data[0].roles;
        },
        REMOVE_USER(state) {
            state.isAuth = false;
            state.user = [];
            state.roles = [];
        },
       
    }
}