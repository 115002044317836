<template>
   
  <nav id="navbar" v-if="getAuthStatus">
    <v-navigation-drawer
      v-model="drawer"
      app
      width="auto"
      
      
    >
    <NavBarList />
    </v-navigation-drawer>

    <v-app-bar app flat>
      <v-app-bar-nav-icon @click="drawer = !drawer"  v-if="getAuthStatus"></v-app-bar-nav-icon>

      <v-toolbar-title class="font-weight-bold">
        <router-link to="/" class="black--text" style="text-decoration: none">PGC Portal</router-link>        
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-spacer></v-spacer>

      <!--v-tooltip bottom  v-if="getAuthStatus">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="mr-7">
            <v-icon size="25">mdi-bell</v-icon></v-btn
          >
        </template>
        <span>Notifications</span>
      </v-tooltip-->

      <v-menu offset-y left  v-if="getAuthStatus">
        <template v-slot:activator="{ on }">
          <v-btn small color="red" depressed fab v-on="on" class="white--text">
            {{ getCurrentUser[0].First_name.substring(0, 1) }}
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar color="indigo">
                <v-icon dark> mdi-account-circle </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  >{{ getCurrentUser[0].First_name }}
                  {{ getCurrentUser[0].Last_name }}</v-list-item-title
                >
                <v-list-item-subtitle>{{
                  getCurrentUser[0].DeptDesc
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item link router to="/ChangePassword">
              <v-list-item-icon>
                <v-icon>mdi-lock-reset</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Change Password</v-list-item-title>
            </v-list-item>

            <v-list-item @click="signOuts()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Sign out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
   

   
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NavBarList from "../Navigation/NavBarList.vue";

export default {
  data: () => ({
    drawer: true,
    echo: null,
  }),
  components: {
    NavBarList,
  },
  created() {
    this.checkAuth();
  },
  computed: {
    ...mapGetters(["getAuthStatus", "getCurrentUser"]),
  },
  methods: {
    ...mapActions(["checkAuth", "signOut"]),
    signOuts() {
      this.signOut()
        .then((resp) => {
          localStorage.removeItem("ua");
          this.$router.push({ name: "Login" });
        })
        .catch((err) => {});
    },
    
  },
  mounted() {
    this.drawer = this.$vuetify.breakpoint.mdAndDown ? false : true;
    this.drawer = this.$route.name === "Watch" ? false : this.drawer;
   
  },
};
</script>

<style lang="scss" scoped>


</style>



