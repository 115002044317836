<template>

  <v-list nav rounded min-width="300">

    <v-list-item link class="mb-0" router to="/home" exact :active-class="activeClass">
      <v-list-item-icon>
        <v-icon>mdi-home</v-icon>
      </v-list-item-icon>
      <v-list-item-title>Home</v-list-item-title>
    </v-list-item>

    <v-list-item link class="mb-0" router to="/jobendorsementGOorPA" exact :active-class="activeClass" v-if="getPermissions(12)">
      <v-list-item-icon>
        <v-icon>mdi-account-alert</v-icon>
      </v-list-item-icon>
      <v-list-item-title>Job Applicants</v-list-item-title>
    </v-list-item>

    <v-list-item link class="mb-0" router to="/jobendorsementHR" exact :active-class="activeClass" v-if="getPermissions(13)">
      <v-list-item-icon>
        <v-icon>mdi-account-alert</v-icon>
      </v-list-item-icon>
      <v-list-item-title>Job Applicants</v-list-item-title>
    </v-list-item>

    <v-list-item link class="mb-0" router to="/jobendorsement" exact :active-class="activeClass" v-if="getPermissions(14)">
      <v-list-item-icon>
        <v-icon>mdi-account-alert</v-icon>
      </v-list-item-icon>
      <v-list-item-title>Job Applicants</v-list-item-title>
    </v-list-item>

 

    <v-list-group prepend-icon="mdi-face-agent" no-action exact :active-class="activeClass" v-if="getPermissions(1) || getPermissions(2)">
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>ICT Technical Supports</v-list-item-title>
        </v-list-item-content>
      </template>
      <!--v-list-item link class="mb-0" router to="/itequipmentrepair" :active-class="activeClass" v-if="getPermissions(1)">
        <v-list-item-content>
          <v-list-item-title>Repair Status</v-list-item-title>
        </v-list-item-content>
      </v-list-item-->

      <v-list-item link class="mb-0" router to="/RepairQueue" :active-class="activeClass" v-if="getPermissions(2)">
        <v-list-item-content>
          <v-list-item-title>Equipment Repair</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!--v-list-item link class="mb-0" router to="/ICTTechAssistance" :active-class="activeClass" v-if="getPermissions(16)">
        <v-list-item-content>
          <v-list-item-title>Technical Assistance</v-list-item-title>
        </v-list-item-content>
      </v-list-item-->
      
    </v-list-group>

    <v-list-item link class="mb-0" router to="/ZoomRequest" exact :active-class="activeClass" v-if="getPermissions(3)">
      <v-list-item-icon>
        <v-icon>mdi-video</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title> Zoom Meeting Request </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link class="mb-0" router to="/TelephoneDirectory" exact :active-class="activeClass" v-if="getPermissions(4)">
      <v-list-item-icon>
        <v-icon>mdi-phone</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Telephone Directory</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link class="mb-0" router to="/ICTequipmentStandardSpecs" exact :active-class="activeClass" v-if="getPermissions(5)">
      <v-list-item-icon>
        <v-icon>mdi-note-check</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Standard Specification <br> in Procuring It Equipment</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link class="mb-0" router to="/GeneralService" exact :active-class="activeClass" v-if="getPermissions(6)">
      <v-list-item-icon>
        <v-icon>mdi-hammer-screwdriver</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>General Service</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link class="mb-0" router @click="setJobPostingUrl()" exact :active-class="activeClass" v-if="getPermissions(18)">
      <v-list-item-icon>
        <v-icon>mdi-briefcase-search</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Job Posting</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!--v-list-group prepend-icon="mdi-file-multiple" no-action :active-class="activeClass">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Document Archiving</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              link
              class="mb-0"
              router
              to="/MyFiles"
              exact
              :active-class="activeClass"
            >
              <v-list-item-content>
                <v-list-item-title>My Files</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              link
              class="mb-0"
              router
              to="/Shared"
              exact
              :active-class="activeClass"
            >
              <v-list-item-content>
                <v-list-item-title>Shared Files</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group-->
  </v-list>




</template>

<script>

import { wsConnect } from "../../plugins/laravel-echo";
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    activeClass: "light-blue--text text--darken-4",
    echo: null,
    navPermission: [
      { role_id: 1, permissions: 0 },
      { role_id: 2, permissions: 0 },
      { role_id: 3, permissions: 0 },
      { role_id: 4, permissions: 0 },
      { role_id: 5, permissions: 0 },
      { role_id: 6, permissions: 0 },
      { role_id: 12, permissions: 0 },
      { role_id: 13, permissions: 0 },
      { role_id: 14, permissions: 0 },
      { role_id: 16, permissions: 0 },
      { role_id: 18, permissions: 0 },
    ],
   
  }),
  mounted() {
    
   this.checkPermission();
   
    
    this.echo = wsConnect();

    this.echo.private("roles").listen("RoleNotification", (message) => {
      if (this.getCurrentUser[0].emp_no == message.message) {
        this.checkPermission();

      }
    });
  },
  methods: {
    ...mapActions(["checkAuth"]),
    checkPermission() {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: "post",
          url: process.env.VUE_APP_API + "auth/me",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("ua")
          },
        })
          .then((resp) => {
            const roles = resp.data[0].roles;
            if(roles.length > 0){
              for(let i = 0; i < roles.length; i++){
                if(this.navPermission.filter(p => p.role_id == roles[i].role_id).length > 0){
                  this.navPermission.filter(p => p.role_id == roles[i].role_id)[0].permissions = roles[i].permissions;
                }
              }
            }else{
              for(let i = 0; i < this.navPermission.length; i++){
                this.navPermission[i].permissions = 0;
              }
            }
            
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
      })
    },
    setJobPostingUrl(){
      let CryptoJS = require("crypto-js");
      let apiToken = localStorage.getItem("ua");
      let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(apiToken), process.env.VUE_APP_SECRET).toString();
      const encodedData = window.btoa(ciphertext); // encode a string

      let url = process.env.VUE_APP_PHRMOJOBPOSTING + '?q='+ encodedData;
   
      //console.log(ciphertext)
      //var bytes  = CryptoJS.AES.decrypt(ciphertext, process.env.VUE_APP_SECRET);
      //var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      //console.log(decryptedData)
      window.location.href = url;
      //window.location.replace(url);
    },
    getPermissions(role_id){
      return this.navPermission.filter(p => p.role_id == role_id)[0].permissions;
    }
  },
  computed: {
    ...mapGetters(["getRoles", "getCurrentUser"]),

  },


};
</script>

<style lang="scss" scoped>
/*

.v-list-group--active>.v-list-group__header .v-list-item,
.v-list-group--active>.v-list-group__header .v-list-item__content,
.v-list-group--active>.v-list-group__header .v-list-group__header__prepend-icon .v-icon {
  color: purple !important;
}




.v-list .v-list-item--active {
  color: purple;
  //background-color: #e91e63 !important;
  //border-color: #e91e63 !important;
}

.v-list-item__icon {
  color: purple;
}
*/
.v-list-item__title {
  align-self: center;
  font-size: 0.875rem;

}
</style>



