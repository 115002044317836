
const config = process.env;
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import axios from 'axios';


export const wsConnect = () => {
  return new Echo({
    broadcaster: 'pusher',
    key: config.VUE_APP_PUSHER_APP_KEY,
    wsHost: config.VUE_APP_PUSHER_HOST,
    wsPort: config.VUE_APP_PUSHER_PORT,
    disableStats: true,
    encrypted: false,
    forceTLS: false,
    enabledTransports: ['ws', 'wss'],

    authorizer: (channel, options) => {

      return {
        authorize: (socketId, callback) => {

          axios({
            method: "POST",
            url: config.VUE_APP_PUSHER_AUTH,
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('ua'),
            },
            data: {
              socket_id: socketId,
              channel_name: channel.name,
            },

          })
            .then((response) => {
              callback(false, response.data);
            })
            .catch((error) => {
              callback(true, error);
            });

        },
      };
    },
  })

}

