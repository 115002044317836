export default {

  data: () => ({
    model: ' ',
    label: ' '
  }),
  methods: {
    init(v, l) {
      this.model = v;
      this.label = l;
      return true;

    },
    required() {

      if (this.model) {
        if (this.model.trim().length < 1) {

          return this.label + ' is required';
        }
        return true;
      }
      return this.label + ' is required';

    },
    requiredNumber() {

      if (this.model) {
        if (this.model.length < 1) {

          return this.label + ' is required';
        }
        return true;
      }
      return this.label + ' is required';

    },
    requiredSelect() {

      if (this.model) {
        if (this.model.length < 1) {

          return this.label + ' is required';
        }
        return true;
      }
      return this.label + ' is required';

    },
    requiredDate() {
      if (this.model) {
        return true;
      }
      return this.label + ' is required';
    },
    minDateToDay(){
      if (this.model) {
        let dd =  new Date();
        let date1 = new Date(dd.getFullYear() + '-' + (dd.getMonth() + 1) + '-' + dd.getDate());
        let date2 = new Date(this.model);
        let Difference_In_Time = date2.getTime() - date1.getTime();
        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
      
        if(Difference_In_Days < 0){
          return this.label + ' should not be less than the current date.';
        }
      }
      return true
    },
    dateRange(start,end){
      let date1 = new Date(start);
      let date2 = new Date(end);
      let diff = date2 - date1;
      if(diff < 0){
        return this.label + ' should greater than start date';
      }
      return true;
    },
    max(val){
      if (this.model) {
        if (this.model > val) {
          return this.label + ' not more than ' + val;
        }
        return true;
      }
      return true;
    },
    maxlen(len) {

      if (this.model) {
        if (this.model.trim().length > len) {
          return this.label + ' must be less than ' + len + ' characters';
        }
        return true;
      }
      return true;
    },
    valid_email() {
      const re = /.+@.+\..+/;

      if (this.model) {
        if (!re.test(this.model)) {
          return this.label + ' must be valid';
        }
        return true;
      }
      return true;
    },
    digit_range(from, to) {
      if (this.model) {

        if (this.model >= from && this.model <= to) {
          return true;
        }
        return this.label + ' should between ' + from + ' and ' + to;
      }
      return true;
    },
    passwordRules() {
      let password_length = this.model.length;
      let valid_password_length = false;
      if (password_length < 5) {
        return this.label + ' atleast minimum of 6 characters';
      }else{
        valid_password_length = true;
      }
      let contains_lovercase = /[a-z]/.test(this.model);
      let contains_number = /\d/.test(this.model);
      let contains_uppercase = /[A-Z]/.test(this.model);

      if (valid_password_length == true && contains_lovercase == true && contains_number == true && contains_uppercase) {
        return true;
      } else {
        return  this.label + 'must contain upper and lower case character';
      }
    }
  }
}