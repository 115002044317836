<template>
  <v-app :style="{ 'background-color': '#ffffff' }">
    <NavBar />

    <v-main>

      <vue-page-transition name="fade-in-up">
        <router-view />
      </vue-page-transition>
    </v-main>
    <!-- Messenger Chat Plugin Code -->
    <!--div id="fb-root"></div-->
    <!-- Your Chat Plugin code -->
    <!--div id="fb-customer-chat" class="fb-customerchat">
    </div-->


  </v-app>

</template>

<script>
//import { initFacebookSdk } from './plugins/initFacebookMessenger';
import NavBar from "./components/Navigation/NavBar.vue";
//import { wsConnect } from "./plugins/laravel-echo";
export default {
  name: "App",
  components: {
    NavBar,

  },
  data: () => ({
    echo: null,
    isRequiredAuth: 0,
  }),
  mounted() {
   // if (process.env.NODE_ENV === 'production') {
    //  initFacebookSdk().then(this.initFBMessengerPlugins());
    //}



    //this.echo = wsConnect();

    //this.echo.private("repairs").listen("TrackEquipmentRepairs", (message) => {

    //});


  },
  methods: {
    initFBMessengerPlugins() {
      const facebookPageId = process.env.VUE_APP_FACEBOOK_PAGE_ID;
      let chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute("page_id", facebookPageId);
      chatbox.setAttribute("attribution", "biz_inbox");

    }
  },
};
</script>
<style lang="css">
.v-application {
  /** font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; */
  /*font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  /* font-family: "Mulish", sans-serif; */
  font-size: 1rem;
  line-height: 1.5;
}
</style>
