<template>
  <v-container fluid>
    <v-card max-width="400" class="mx-auto" :style="{ 'margin-top':'80px' }" elevation="6">
      <v-toolbar dark color="#0d6efd" flat>


        <v-toolbar-title>Provincial Government of Cagayan Portal</v-toolbar-title>


      </v-toolbar>
      <v-card-text>


        <v-row>
          <v-col class="text-center" cols="12">
            <v-img :style="{ margin: '0 auto', width: '40%', height: 'auto' }" lazy-src="../assets/hdlogo.png"
              src="../assets/hdlogo.png"></v-img>

          </v-col>
          <v-col cols="12">
            <v-form ref="form" v-model="valid" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field v-model="payload.username" label="Username" placeholder="Username" outlined :rules="[
                  vv.init(payload.username, 'Username'),
                  vv.required(),
                ]"></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field v-model="payload.password" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'" name="input-10-2" label="Password" class="input-group--focused"
                  @click:append="show2 = !show2" outlined :rules="[
                    vv.init(payload.password, 'Password'),
                    vv.required(),
                  ]"></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-btn :style="{ 'width': '100%' }" color="#0d6efd" dark rounded  x-large @click="signin()">
                  Sign-in
                </v-btn>
              </v-col>
              <v-col cols="12 mt-5" v-if="invalidCrid">
                <v-alert border="left" colored-border type="error" elevation="2" color="red accent-4">
                  <span :style="{ 'color': 'red' }">Invalid Credentials</span>

                </v-alert>
              </v-col>
              <v-col cols="12 mt-5" v-if="notPermitted">
                <v-alert border="left" colored-border type="error" elevation="2" color="red accent-4">
                  <span :style="{ 'color': 'red' }">It is not authorized for your account to use this application.</span>

                </v-alert>
              </v-col>

            </v-row>
          </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    


  </v-container>
</template>

<script>
export default {
  name: "HelloWorld2",

  data: () => ({
    dialog: false,
    valid: true,
    show2: false,
    loading: false,
    payload: {
      username: "",
      password: "",
    },
    vv: [],
    service: 0,
    invalidCrid: false,
    notPermitted: false
  }),
  created() {
    this.vv = this.$vv.methods;
  },
  mounted() {

  },
  methods: {

    signin() {
      if (this.$refs.form.validate() == true) {
        this.loading = true;
        this.$axios({
          method: "post",
          url: process.env.VUE_APP_API + "auth/login",
          data: this.payload,
        })
          .then((resp) => {
            this.loading = false;
            this.invalidCrid = false;
            localStorage.setItem("ua", resp.data.access_token);
            if (localStorage.getItem("ua")) {
              this.loading = false;
              if (resp.data.permitted > 0) {
                this.notPermitted = false;
                this.$router.push({ name: "Home" });
              } else {
                this.notPermitted = true;
              }
            }
          })
          .catch((err) => {
            this.loading = false;
            this.invalidCrid = true;
          });
      }
    },
  },
}
</script>
